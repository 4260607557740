@import "react-day-picker/dist/style.css";

body {
  margin: 0;
  font-family: 'Varela', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

h1, h3 {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  margin-block: unset;
}

h2, h4, h5 {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  margin-block: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
